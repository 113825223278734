import { getUserToken, supabase } from '~/services/supabase'
import { reportError } from '~/services/errors'
import { CollaboratorEntry, DeleteCollaboratorPayload, DeleteCollaboratorResponse, EditCollaboratorPayload, InviteCollaboratorPayload, InviteCollaboratorResponse } from '~/types/database/collaborators'
import { Database } from '~/types/supabase'
import { requiredRole } from '~/services/database/permissions'
import { Job } from '~/types/comic/chapter'

const inviteCollaborator = async (payload: InviteCollaboratorPayload) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/collaborator/invite', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify(payload)
    })
    const result: InviteCollaboratorResponse = await data.json()
    if (result.error) {
      const error = new Error(result.error.code)
      reportError(error)
      throw result.error
    }
    return result
  }
}

const getCollaborators = (id: Database['public']['Tables']['series']['Row']['id']) => async () => {
  const { data, error } = await supabase
    .from('series_permissions')
    .select('*, users(pseudo, first_name, last_name, email)')
    .eq('series_id', id)
    .eq('revoked', false)
  if (error) {
    reportError(error)
    throw error
  }
  const formattedData: CollaboratorEntry[] = data.map(entry => ({
    pseudo: entry.users?.pseudo,
    first_name: entry.users?.first_name,
    last_name: entry.users?.last_name,
    email: entry.users?.email as string,
    userId: entry.user_id,
    permissionId: entry.id,
    permission: entry.permission
  }))
  return formattedData
}

const updateCollaborator = async (payload: EditCollaboratorPayload) => {
  const row: Database['public']['Tables']['series_permissions']['Update'] = {
    id: payload.permissionId,
    permission: payload.permission
  }

  const { data, error } = await supabase
    .from('series_permissions')
    .update(row)
    .eq('id', payload.permissionId)
    .select()

  if (error) {
    reportError(error)
    throw error
  }

  return data
}

const deleteCollaborator = async (payload: DeleteCollaboratorPayload) => {
  const token = await getUserToken()
  if (token) {
    const data = await fetch('/api/collaborator/delete', {
      method: 'POST',
      headers: {
        'X-SUPABASE-TOKEN': token
      },
      body: JSON.stringify(payload)
    })
    
    const result: DeleteCollaboratorResponse = await data.json()
    if (result.error) {
      const error = new Error(result.error.message)
      reportError(error)
      throw error
    }

    return result
  }
}

const hasCollaboratorForJob = async (seriesId: string, job: string) => {
  const collaborators = await getCollaborators(seriesId)()
  const requiredRoleForCurrentProgress = requiredRole(job as Job)
  return collaborators.some(collaborator => collaborator.permission === requiredRoleForCurrentProgress)
}

export {
  inviteCollaborator,
  updateCollaborator,
  getCollaborators,
  deleteCollaborator,
  hasCollaboratorForJob
}